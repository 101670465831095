import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import ResumeBtn from './ResumeBtn'
import '../styles/navbar.css'

const NavBar = ({setActive}) => {
    return(
        <header className="navbar">
            <section className="navbar-section">
                <RouterLink to="/"><h3 className="navbar-brand mr-2">Roger</h3></RouterLink>
            </section>
            <section className="navbar-section">
                <ResumeBtn />
            </section>
        </header>
    )
}

export default NavBar