import React from 'react'
import {animateScroll as scroll} from 'react-scroll';

import '../styles/totop.css'

function ToTop(){
    const goToTop = () => {
        scroll.scrollToTop()
    }

    return(
        <div>
            <button className="totop btn s-circle" onClick={goToTop}><i className="icon icon-arrow-up"></i></button>
        </div>
        
    )
}

export default ToTop