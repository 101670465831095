import React, { useState } from 'react'
import NavBar from '../Components/Navbar'
import Intro from '../pages/intro'
import Art from '../pages/art'
import ToTop from '../Components/ToTop'

import '../styles/home.css'

const Home = () => {
    let [active, setActive] = useState(false)


    return (
        <div className="off-canvas">

            <div className="off-canvas-content home">
                <NavBar setActive={setActive}/>
                <Intro />
                <Art />
                <ToTop offcanActive={active}/>
            </div>
        </div>
    );
}

export default Home;
