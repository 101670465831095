import React from 'react'
import ArtTile from '../Components/ArtTile'

import '../styles/art.css'


const Art = () => {
    
    return(
        <div id="art" className="hero hero-lg">
            <div className="container">
                <div>
                    <h1>Artwork</h1>
                </div>
                <div className="columns">
                    <div className="artcol column col-6 col-xl-12">
                        <ArtTile id="1"/>
                        <ArtTile id="3"/>
                        <ArtTile id="5"/>
                        <ArtTile id="7"/>
                        <ArtTile id="9"/>
                    </div>
                    <div className="artcol column col-6 col-xl-12">
                        <ArtTile id="2"/>
                        <ArtTile id="4"/>
                        <ArtTile id="6"/>
                        <ArtTile id="8"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Art