import React from 'react'
import resume from '../assets/resume.pdf'
import '../styles/resume.css'

function ResumeBtn(){
    return(
        <a id="resumebtn" className="btn btn-primary" href={resume} target="_blank" rel="noopener noreferrer">Resume</a>
    )
}

export default ResumeBtn