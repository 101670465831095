import React from 'react'

import image from "../assets/intro.png"
import '../styles/intro.css'

const Intro = () => {
    return(
        <div id="intro" className="hero">
            <div className="hero-body container">
            <div className="columns">
                <div className="column col-6 col-md-12 introcontent">
                    <h1>Hi, I'm Roger!</h1>
                    <p>I'm a Software Engineering student taking a Fine Arts Minor. Here's some of the art I've made over the years!
                    </p>
                </div>
                <div className="column col-6 col-md-12 introimgcontainer">
                    <img id="introimg" className="" src={image} alt="Introduction, me waving"></img>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Intro