import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../styles/arttile.css'
import info from '../constants/imageInfo.json'

const images = require.context('../assets', false, /\.(png|jpe?g|svg)$/);

function ArtTile(props){
    const navigate = useNavigate();
    let imagePath;

    console.log(props.id)
    const data = info[props.id]

    try {
        imagePath = images(`./${data.image}`);
    } catch (error) {
        console.error(`Image not found: ${data.image}`);
        return <p>Image not found</p>;
    }
    

    const handleClick = () => {
        navigate('/art/' + props.id);
    };

    return(
        <div className="arttile card">
            <img onClick={handleClick} className="artimage" src={imagePath} alt={data.title} loading="lazy"/>
            <div className="overlay"/>
            <div className="image-text">
                <h3>{data.title}</h3>
                <p>{data.description}</p>
            </div>
        </div>
    )
}

export default ArtTile