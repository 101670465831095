import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import NavBar from '../Components/Navbar'
import ArtDetailContent from './artDetailContent';
import ToTop from '../Components/ToTop'

import '../styles/home.css'

const ArtDetail = () => {
    const { id } = useParams();
    let [active, setActive] = useState(false)

    return (
        <div className="off-canvas">
            <div className="off-canvas-content home">
                <NavBar setActive={setActive}/>
                <ArtDetailContent id={id}/>
                <ToTop offcanActive={active}/>
            </div>
        </div>
    );
}

export default ArtDetail;
