import React from 'react'
import '../styles/artdetail.css'
import info from '../constants/imageInfo.json'

const images = require.context('../assets', false, /\.(png|jpe?g|svg)$/);
const videos = require.context('../assets', false, /\.(mp4)$/);


const ArtDetailContent = (props) => {
    let imagePath;
    const data = info[props.id]

    try {
        imagePath = images(`./${data.image}`);
    } catch (error) {
        console.error(`Image not found: ${data.image}`);
        return <p>Image not found</p>;
    }

    return (
        <div id="artdetail" className="hero hero-lg container">
            <div className="hero-body columns">
                <div className="column col-12 imageBox">
                    <img className="artMainImage img-responsive" src={imagePath} alt="Me floating with a balloon"/>
                </div> 

                <div className="artcontent column col-12">
                    <h1>{data.title}</h1>
                    <p>{data.body}</p>
                </div>

                {data.video && 
                <div className="column col-12">
                <video className="artVideo" controls>
                    <source src={videos(`./${data.video}`)} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
                }

                <div className="column col-12 imagesBox">
                    {data.images && data.images.map((image, index) => (
                        <div className="imageBox">
                        <img key={index} className="artImage img-responsive" src={images(`./${image}`)} alt={`Image ${index + 1}`} />
                        </div> 
                    ))}
                </div>
            </div> 
        </div>
    );
}

export default ArtDetailContent;
