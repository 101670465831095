import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import ArtDetail from './pages/artDetail';
import ScrollToTop from './Components/ScrollToTop';

import "./styles/colours.css"

const App = () => {
    return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route index element={<Home />} />
            <Route path="/art/:id" element={<ArtDetail />} />
        </Routes>
    </BrowserRouter>
    )
}

export default App;
